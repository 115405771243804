import React, { useState } from 'react';

export default function IntervalSelector({ onIntervalChange }) {
    const [selectedInterval, setSelectedInterval] = useState(1); // Default to 1 minute

    const handleIntervalChange = (e) => {
        const interval = parseInt(e.target.value, 10); // Get the selected interval as an integer
        setSelectedInterval(interval); // Update local state
        onIntervalChange(interval); // Call the function passed down from the parent
    };

    return (
        <div className="interval-selector bg-white dark:bg-gray-800 rounded-lg flex items-center space-x-1 md:space-x-2">
            <label htmlFor="interval" className="text-gray-800 dark:text-gray-300">Update Interval:</label>
            <select
                id="interval"
                value={selectedInterval}
                onChange={handleIntervalChange}
                className="px-2 py-1 h-10 bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-300 rounded-lg text-sm"
            >
                <option value={1}>1 Minute</option>
                <option value={5}>5 Minutes</option>
                <option value={10}>10 Minutes</option>
                <option value={20}>20 Minutes</option>
            </select>
        </div>
    );
}
