import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
    UserIcon,
    ArrowLeftOnRectangleIcon,
    PencilSquareIcon,
    UserPlusIcon,
    ArrowRightOnRectangleIcon,
    Bars3Icon,
    XMarkIcon,
    SunIcon,
    MoonIcon,
    HomeIcon,
    InformationCircleIcon,
    MapPinIcon,
    PhoneIcon,
    LightBulbIcon,
} from "@heroicons/react/24/solid";
import "./Navbar.css";

export default function Navbar({ loggedIn, darkMode, toggleDarkMode, setLogin, handleLogout }) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    // Check if the current path matches the link's path
    const isActiveLink = (path) => location.pathname === path;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                mobileMenuRef.current &&
                !mobileMenuRef.current.contains(event.target) &&
                !event.target.closest(".hamburger-button")
            ) {
                setMenuOpen(false);
            }

            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogoutClick = () => {
        handleLogout();
        setShowDropdown(false);
    };

    return (
        <div
            className={`fixed top-0 left-0 h-16 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out ${
                darkMode ? "dark-image" : "light-image"
            } flex justify-between items-center px-6 shadow-lg`}
        >
            <Link to="/" className="pointer">
                <img
                    alt="decoration"
                    src={darkMode ? "/logo-light.png" : "/logo-dark.png"}
                    className={`transition-colors duration-500 ease-in-out ${
                        darkMode ? "dark:text-gray-100" : "text-gray-900"
                    }`}
                />
            </Link>

            {/* Hamburger Menu for Mobile */}
            <div className="flex md:hidden items-center">
                <button
                    className="hamburger-button focus:outline-none"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    {menuOpen ? (
                        <XMarkIcon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
                    ) : (
                        <Bars3Icon className="h-6 w-6 text-gray-600 dark:text-gray-300" />
                    )}
                </button>
            </div>

            {/* Desktop Navbar */}
            <div className="hidden md:flex items-center space-x-6 ml-auto">
                <Link
                    to={loggedIn ? "/map" : "/"}
                    className={`hover:underline ${darkMode ? "text-gray-300" : "text-gray-600"} ${
                        isActiveLink(loggedIn ? "/map" : "/") ? "underline" : ""
                    } border-r-2 border-gray-300 dark:border-gray-600 pr-6`}
                >
                    {loggedIn ? "Map" : "Home"}
                </Link>

                <Link
                    to="/about"
                    className={`hover:underline ${darkMode ? "text-gray-300" : "text-gray-600"} ${
                        isActiveLink("/about") ? "underline" : ""
                    } border-r-2 border-gray-300 dark:border-gray-600 pr-6`}
                >
                    About
                </Link>

                <Link
                    to="/contact"
                    className={`hover:underline ${darkMode ? "text-gray-300" : "text-gray-600"} ${
                        isActiveLink("/contact") ? "underline" : ""
                    } border-r-2 border-gray-300 dark:border-gray-600 pr-6`}
                >
                    Contact
                </Link>

                <Link
                    to="/faq"
                    className={`hover:underline ${darkMode ? "text-gray-300" : "text-gray-600"} ${
                        isActiveLink("/faq") ? "underline" : ""
                    } border-r-2 border-gray-300 dark:border-gray-600 pr-6`}
                >
                    FAQ
                </Link>

                {loggedIn ? (
                    // Show profile icon and dropdown when logged in
                    <div className="relative">
                        <div
                            className="flex items-center ml-4 cursor-pointer"
                            onClick={() => setShowDropdown(!showDropdown)}
                        >
                            <UserIcon
                                className={`h-6 w-6 ${
                                    darkMode ? "text-gray-300" : "text-gray-600"
                                }`}
                            />
                        </div>

                        {showDropdown && (
                            <div
                                ref={dropdownRef}
                                className="absolute right-0 top-12 w-40 bg-white shadow-md rounded-md dark:bg-gray-800 dark:text-gray-200 z-50"
                            >
                                <div className="p-2 space-y-2">
                                    <Link
                                        to="/account"
                                        className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded-md w-full text-left"
                                        onClick={() => setShowDropdown(false)}
                                    >
                                        <PencilSquareIcon className="h-5 w-5" />
                                        <span>Account</span>
                                    </Link>
                                    <button
                                        onClick={handleLogoutClick}
                                        className="flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700 p-2 rounded-md w-full text-left"
                                    >
                                        <ArrowLeftOnRectangleIcon className="h-5 w-5" />
                                        <span>Logout</span>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    // Show Login and Signup links when not logged in
                    <>
                        <Link
                            to="/login"
                            className={`hover:underline ${
                                darkMode ? "text-gray-300" : "text-gray-600"
                            } ${
                                isActiveLink("/login") ? "underline" : ""
                            } border-r-2 border-gray-300 dark:border-gray-600 pr-5`}
                        >
                            Login
                        </Link>
                        <Link
                            to="/signup"
                            className={`hover:underline ${
                                darkMode ? "text-gray-300" : "text-gray-600"
                            } ${isActiveLink("/signup") ? "underline" : ""}`}
                        >
                            Signup
                        </Link>
                    </>
                )}

                {/* Dark Mode Switch with Icons */}
                <label className="relative inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={darkMode}
                        onChange={toggleDarkMode}
                        className="sr-only"
                    />
                    <div className="w-11 h-6 bg-white dark:bg-gray-700 rounded-full flex items-center justify-between">
                        <div
                            className={`absolute left-0 top-0 w-6 h-6 bg-blue-700 dark:bg-blue-500 rounded-full transform transition-transform ${
                                darkMode ? "translate-x-5" : "translate-x-0"
                            } flex items-center justify-center`}
                        >
                            {darkMode ? (
                                <MoonIcon className="h-4 w-4 text-gray-800" />
                            ) : (
                                <SunIcon className="h-4 w-4 text-stone-50" />
                            )}
                        </div>
                    </div>
                </label>
            </div>

            {/* Mobile Dropdown Menu */}
        {menuOpen && (
            <div
                ref={mobileMenuRef}
                className="absolute top-16 left-0 w-full bg-gray-200 dark:bg-gray-800 shadow-md z-50 md:hidden"
            >
                <div className="flex flex-col p-4 space-y-4">
                    <label className="relative inline-flex items-center cursor-pointer mb-4">
                        <input
                            type="checkbox"
                            checked={darkMode}
                            onChange={toggleDarkMode}
                            className="sr-only"
                        />
                        <div className="w-11 h-6 bg-white dark:bg-gray-700 rounded-full flex items-center justify-between relative">
                            <div
                                className={`absolute left-0 top-0 w-6 h-6 bg-blue-700 dark:bg-blue-500 rounded-full transform transition-transform ${
                                    darkMode ? "translate-x-5" : "translate-x-0"
                                } flex items-center justify-center`}
                            >
                                {darkMode ? (
                                    <MoonIcon className="h-4 w-4 text-gray-800" />
                                ) : (
                                    <SunIcon className="h-4 w-4 text-stone-50" />
                                )}
                            </div>
                        </div>
                    </label>

                    <Link
                        to={loggedIn ? "/map" : "/"}
                        className={`hover:underline ${
                            darkMode ? "text-gray-300" : "text-gray-600"
                        } flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                            isActiveLink(loggedIn ? "/map" : "/") ? "underline" : ""
                        }`}
                    >
                        {loggedIn ? (
                            <MapPinIcon className="h-5 w-5" />
                        ) : (
                            <HomeIcon className="h-5 w-5" />
                        )}
                        <span>{loggedIn ? "Map" : "Home"}</span>
                    </Link>

                    <Link
                        to="/about"
                        className={`hover:underline ${
                            darkMode ? "text-gray-300" : "text-gray-600"
                        } flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                            isActiveLink("/about") ? "underline" : ""
                        }`}
                    >
                        <InformationCircleIcon className="h-5 w-5" />
                        <span>About</span>
                    </Link>

                    <Link
                        to="/contact"
                        className={`hover:underline ${
                            darkMode ? "text-gray-300" : "text-gray-600"
                        } flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                            isActiveLink("/contact") ? "underline" : ""
                        }`}
                    >
                        <PhoneIcon className="h-5 w-5" />
                        <span>Contact Us</span>
                    </Link>

                    <Link
                        to="/faq"
                        className={`hover:underline ${
                            darkMode ? "text-gray-300" : "text-gray-600"
                        } flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                            isActiveLink("/faq") ? "underline" : ""
                        }`}
                    >
                        <LightBulbIcon className="h-5 w-5" />
                        <span>FAQ</span>
                    </Link>

                    {loggedIn && (
                        <>
                            <Link
                                to="/account"
                                className={`hover:underline ${
                                    darkMode ? "text-gray-300" : "text-gray-600"
                                } flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                                    isActiveLink("/account") ? "underline" : ""
                                }`}
                                onClick={() => setMenuOpen(false)}
                            >
                                <UserIcon className="h-5 w-5" />
                                <span>Account</span>
                            </Link>

                            <Link
                                to="#"
                                onClick={handleLogoutClick}
                                className={`flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                                    darkMode ? "text-gray-300" : "text-gray-600"
                                }`}
                            >
                                <ArrowLeftOnRectangleIcon className="h-5 w-5" />
                                <span>Logout</span>
                            </Link>
                        </>
                    )}

                    {!loggedIn && (
                        <>
                            <Link
                                to="/login"
                                className={`hover:underline ${
                                    darkMode ? "text-gray-300" : "text-gray-600"
                                } flex items-center space-x-2 border-b border-gray-300 dark:border-gray-600 pb-2 ${
                                    isActiveLink("/login") ? "underline" : ""
                                }`}
                                onClick={() => setMenuOpen(false)}
                            >
                                <ArrowRightOnRectangleIcon className="h-5 w-5" />
                                <span>Login</span>
                            </Link>

                            <Link
                                to="/signup"
                                className={`hover:underline ${
                                    darkMode ? "text-gray-300" : "text-gray-600"
                                } flex items-center space-x-2 ${
                                    isActiveLink("/signup") ? "underline" : ""
                                }`}
                                onClick={() => setMenuOpen(false)}
                            >
                                <UserPlusIcon className="h-5 w-5" />
                                <span>Signup</span>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        )}
        </div>
    );
}
