// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App{
  align-items: center;
  justify-content:center ;
}

.App hr{

  width: 90%;
  height: 2px;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 10%;
}


nav{
  margin-bottom: 20px;

}

/* ul{
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;

} */

li{
  margin: 0 10px;

}

input{
  padding: 5px;
  width: 200px;
  margin:5px
}

button{
  padding: 5px 10px;
  cursor: pointer;
}

form{
  display: flex;
 flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.pointer {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;;EAEE,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;AACpB;;;AAGA;EACE,mBAAmB;;AAErB;;AAEA;;;;;;GAMG;;AAEH;EACE,cAAc;;AAEhB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ;AACF;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;CACd,sBAAsB;AACvB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".App{\n  align-items: center;\n  justify-content:center ;\n}\n\n.App hr{\n\n  width: 90%;\n  height: 2px;\n  background-color: black;\n  border: 1px solid #ccc;\n  border-radius: 10%;\n}\n\n\nnav{\n  margin-bottom: 20px;\n\n}\n\n/* ul{\n  list-style: none;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n\n} */\n\nli{\n  margin: 0 10px;\n\n}\n\ninput{\n  padding: 5px;\n  width: 200px;\n  margin:5px\n}\n\nbutton{\n  padding: 5px 10px;\n  cursor: pointer;\n}\n\nform{\n  display: flex;\n flex-direction: column;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
