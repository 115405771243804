import React, { useState } from "react";

const Admin = ({ darkMode }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log("Email:", email);
        console.log("Password:", password);
    };

    return (
        <div
            className={`min-h-screen flex items-center justify-center bg-fixed bg-cover bg-center transition-colors duration-300 ease-in-out ${
                darkMode ? "dark-image" : "light-image"
            }`}
            style={{ backgroundImage: `url('${darkMode ? "/map-dark.png" : "/map-light.png"}')` }}
        >
            <div
                className={`bg-white dark:bg-gray-800 bg-opacity-70 p-8 rounded-lg shadow-md w-full max-w-md`}
            >
                <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-gray-100">
                    Admin Form
                </h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Email Input */}
                    <div className="relative">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 border border-gray-300 dark:border-gray-500 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            placeholder="Enter your email"
                        />
                        <label
                            htmlFor="email"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400"
                        >
                            Enter email
                        </label>
                    </div>

                    {/* Password Input */}
                    <div className="relative">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 dark:border-gray-500 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            placeholder="Enter your password"
                        />
                        <label
                            htmlFor="password"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400"
                        >
                            Enter password
                        </label>
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors duration-300"
                    >
                        Submit
                    </button>

                    {submitted && (
                        <p className="mt-4 text-green-500">Form submitted successfully!</p>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Admin;
