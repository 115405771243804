import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Dashboard({ selectedDevice }) {
    const [deviceData, setDeviceData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    console.log("Selected Device in Dashboard:", selectedDevice);

    // Error handler function to categorize error types and display backend messages
    const handleError = (error) => {
        if (error.code === "ECONNABORTED") {
            return "Request timeout. Please try again.";
        } else if (error.response) {
            // If the backend returns a specific error message, use it
            if (error.response.data && error.response.data.error) {
                return `Error ${error.response.status}: ${error.response.data.error}`;
            } else if (error.response.status >= 400 && error.response.status < 500) {
                return `Error ${error.response.status}: ${error.response.data.message || "Something went wrong."}`;
            }
            if (error.response.status >= 500) {
                return `Server error (Status ${error.response.status}). Please try again later.`;
            }
        } else if (error.request) {
            return "Network error. Please check your connection and try again.";
        } else {
            return "An unknown error occurred. Please try again.";
        }
    };

    useEffect(() => {
        if (selectedDevice && selectedDevice.imei) {
            setLoading(true);
            setError(null);

            axios
                .get(`/api/devices/${selectedDevice.imei}/info`, { withCredentials: true })
                .then((res) => {
                    if (res.data && Object.keys(res.data).length > 0) {
                        setDeviceData(res.data);
                    } else {
                        setDeviceData(null);
                        setError("No data available for this device.");
                    }
                })
                .catch((err) => {
                    const errorMessage = handleError(err);
                    setError(errorMessage);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setDeviceData(null);
        }
    }, [selectedDevice]);

    const getSignalStrengthPercentage = (strength) => {
        if (strength >= 1 && strength <= 31) {
            return Math.round((strength / 31) * 100) + "%";
        } else {
            return "No Signal";
        }
    };

    const getBatteryPercentage = (battery) => {
        const bat = Math.round(((battery - 10.2) / 2.2) * 100);
        if (bat > 0) {
            return bat + "%";
        } else {
          return "0%";
        }
    };

    if (loading) {
        return (
            <div className="text-center text-lg text-gray-700 dark:text-gray-300">
                Loading device information...
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }

    if (!selectedDevice) {
        return <div className="text-center text-lg text-gray-500">No device selected</div>;
    }

    return (
        <div className="dashboard max-w-full overflow-x-auto p-4 bg-white dark:bg-gray-800 rounded-lg">
            {deviceData ? (
                <div className="block md:table bg-white dark:bg-gray-900">
                    {/* Table for larger screens */}
                    <table className="hidden md:table min-w-full table-auto">
                        <thead>
                            <tr className="bg-gray-200 dark:bg-gray-700">
                                <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-300">Device Name</th>
                                <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-300">Battery</th>
                                <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-300">Signal Strength</th>
                                <th className="px-4 py-2 text-left text-gray-700 dark:text-gray-300">Last Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-t border-gray-300 dark:border-gray-600">
                                <td className="px-4 py-2 text-gray-900 dark:text-gray-100">
                                    {selectedDevice.name || selectedDevice.model}
                                </td>
                                <td className="px-4 py-2 text-gray-900 dark:text-gray-100">
                                {deviceData.battery !== undefined
                                    ? getBatteryPercentage(deviceData.battery)
                                    : "N/A"}
                                </td>
                                <td className="px-4 py-2 text-gray-900 dark:text-gray-100">
                                    {deviceData.signalStrength !== undefined
                                        ? getSignalStrengthPercentage(deviceData.signalStrength)
                                        : "N/A"}
                                </td>
                                <td className="px-4 py-2 text-gray-900 dark:text-gray-100">
                                    {deviceData.lastKnownLat !== undefined && deviceData.lastKnownLong !== undefined
                                        ? `${deviceData.lastKnownLat}, ${deviceData.lastKnownLong}`
                                        : "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {/* Stacked layout for mobile */}
                    <div className="block md:hidden">
                        <div className="grid grid-cols-2 gap-x-4 gap-y-0 text-sm">
                            <div className="flex items-center font-bold text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                Device Name:
                            </div>
                            <div className="flex items-center text-gray-900 dark:text-gray-100 bg-gray-50 dark:bg-gray-900 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                {selectedDevice.name || selectedDevice.model}
                            </div>

                            <div className="flex items-center font-bold text-gray-700 dark:text-gray-300 bg-gray-200  dark:bg-gray-700 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                Battery:
                            </div>
                            <div className="flex items-center text-gray-900 dark:text-gray-100 bg-gray-50 dark:bg-gray-900 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                 {deviceData.battery !== undefined
                                    ? getBatteryPercentage(deviceData.battery)
                                    : "N/A"}
                            </div>

                            <div className="flex items-center font-bold text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                Signal Strength:
                            </div>
                            <div className="flex items-center text-gray-900 dark:text-gray-100 bg-gray-50 dark:bg-gray-900 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                {deviceData.signalStrength !== undefined
                                    ? getSignalStrengthPercentage(deviceData.signalStrength)
                                    : "N/A"}
                            </div>

                            <div className="flex items-center font-bold text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                Last Location:
                            </div>
                            <div className="flex items-center text-gray-900 dark:text-gray-100 bg-gray-50 dark:bg-gray-900 px-4 py-2 border-b border-gray-500 dark:border-gray-400">
                                {deviceData.lastKnownLat !== undefined && deviceData.lastKnownLong !== undefined
                                    ? `${deviceData.lastKnownLat}, ${deviceData.lastKnownLong}`
                                    : "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="text-center text-lg text-gray-500 dark:text-gray-300">
                    No data available for this device.
                </div>
            )}
        </div>
    );
}
