import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Home({ setImei, darkMode }) {
    const [inputImei, setInputImei] = useState(""); // Local state for IMEI input
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(""); // Error state for invalid IMEI
    const [showTooltip, setShowTooltip] = useState(false); // Tooltip visibility state
    const navigate = useNavigate(); // Hook for navigation

    // Validate IMEI input (basic: length 15)
    const validateImei = (imei) => {
        if (!imei) {
            setError("IMEI is required.");
            return false;
        } else if (imei.length !== 15) {
            setError("Invalid IMEI number. Must be exactly 15 digits.");
            return false;
        } else if (!/^\d+$/.test(imei)) {
            setError("IMEI must contain only numbers.");
            return false;
        }
        setError(""); // No errors
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(""); // Clear error

        if (!validateImei(inputImei)) {
            return;
        }

        setLoading(true); // Start loading

        // Simulate an API request delay
        setTimeout(() => {
            setLoading(false);
            setImei(inputImei); // Update the IMEI in the parent component
            navigate("/freemap"); // Navigate to FreeMap view
        }, 2000);
    };

    const handleInputChange = (e) => {
        const imeiValue = e.target.value;
        setInputImei(imeiValue); // Update local state
        validateImei(imeiValue); // Real-time validation
    };

    return (
        <div
            className={`min-h-screen flex items-center justify-center bg-fixed bg-cover bg-center transition-colors duration-300 ease-in-out ${
                darkMode ? "dark-image" : "light-image"
            }`}
            style={{ backgroundImage: `url('${darkMode ? "/map-dark.png" : "/map-light.png"}')` }}
        >
            <form
                onSubmit={handleSubmit}
                className="bg-white dark:bg-gray-800 bg-opacity-70 p-8 rounded-lg shadow-md w-full max-w-md"
            >
                <img
                    alt="decoration"
                    src={darkMode ? "/logo-light.png" : "/logo-dark.png"}
                    className={`transition-colors duration-500 ease-in-out mx-auto mb-4
                        ${darkMode ? "dark:text-gray-100" : "text-gray-900"}`}
                />

                <div className="relative">
                    {/* IMEI Input */}
                    <input
                        type="text"
                        id="imei"
                        value={inputImei}
                        onChange={handleInputChange} // Handle change with validation
                        className={`peer w-full p-3 pt-4 border ${
                            error ? "border-red-500" : "border-gray-300 dark:border-gray-500"
                        } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                            error
                                ? "focus:ring-red-500"
                                : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                        } transition-colors duration-300 ease-in-out`}
                        placeholder=" "
                        required
                    />
                    {/* Floating Label */}
                    <label
                        htmlFor="imei"
                        className={`absolute left-4 top-1 text-gray-500 dark:text-gray-400 duration-300 transform origin-[0] transition-all
                        ${
                            inputImei
                                ? "scale-75 -translate-y-6" // Floats label when input has value
                                : "peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-4" // Default behavior when no value
                        }          peer-focus:scale-75 peer-focus:-translate-y-6`} // Floats label on focus
                    >
                        Enter The Device IMEI
                    </label>

                    {/* Question Mark Icon */}
                    <button
                        type="button"
                        className="absolute right-3 top-3.5 text-gray-500 hover:text-gray-600 dark:text-gray-300 dark:hover:text-gray-100"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        aria-label="More info"
                    >

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                    </svg>


                    </button>

                    {/* Tooltip */}
                    {showTooltip && (
                        <div
                            className="absolute left-0 right-0 mx-auto top-12 p-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 text-xs rounded-lg shadow-lg"
                            style={{ maxWidth: "90%", whiteSpace: "normal" }}
                        >
                            Enter the 15-digit IMEI number found on the back of your GPS device.
                        </div>
                    )}

                    {/* Error Message */}
                    {error && (
                        <p className="text-red-500 dark:text-red-400 text-sm mt-2">{error}</p>
                    )}
                </div>

                {/* Submit Button with Loading Spinner */}
                <button
                    type="submit"
                    className="w-full bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-md
                    hover:bg-indigo-700 dark:hover:bg-indigo-600 transition-colors duration-300 ease-in-out mt-4 flex items-center justify-center"
                    disabled={loading}
                >
                    {loading ? (
                        <svg
                            className="animate-spin h-5 w-5 mr-3 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 20a8 8 0 01-8-8H0c0 6.627 5.373 12 12 12v-4z"
                            ></path>
                        </svg>
                    ) : (
                        "Search"
                    )}
                </button>
            </form>

           {/* Footer Section */}
           <div className="fixed bottom-0 left-0 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out flex justify-center items-center shadow-lg p-4 text-center">
                <p className="text-gray-500 dark:text-gray-400">
                    &copy; 2024 Liveblip. All rights reserved.
                </p>
            </div>
        </div>
    );
}
