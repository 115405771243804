import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Home from "./screens/Home/Home";
import Navbar from "./components/Navbar";
import FreeMap from "./screens/Freemap/Freemap";
import Account from "./screens/Account";
import Admin from "./screens/Admin/Admin";
import Map from "./screens/Map/Map";
import Contact from "./screens/Contact";
import About from "./screens/About";
import FAQ from "./screens/FAQ";
import Popup from "./components/Popup";
import  { jwtDecode } from "jwt-decode"; // Fix the import

function App() {
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = sessionStorage.getItem("darkMode");
        return savedMode === 'true';
    });
    const [loggedIn, setLogin] = useState(false);
    const [imei, setImei] = useState("");
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [tokenExpiration, setTokenExpiration] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const inactivityTimeoutRef = useRef(null); // Track inactivity timeout
    const isPopupActive = useRef(false); // Track if the popup is currently shown
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const lastTokenRefreshTime = useRef(Date.now()); // Keep track of the last refresh time


    const toggleDarkMode = () => {
        setDarkMode((prevMode) => {
            const newMode = !prevMode;
            sessionStorage.setItem("darkMode", newMode);
            return newMode;
        });
    };

    useEffect(() => {
        document.documentElement.classList.toggle("dark", darkMode);
    }, [darkMode]);

    // Get and decode the token
    const getToken = () => {
        const token = localStorage.getItem("authToken");
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                console.log("Decoded Token:", decodedToken);
                if (decodedToken.exp > currentTime) {
                    setLogin(true);
                    setTokenExpiration(decodedToken.exp);
                    return { decodedToken, currentTime };
                } else {
                    localStorage.removeItem("authToken");
                    setLogin(false);
                }
            } catch (error) {
                console.error("Error decoding token:", error);
            }
        }
        return null;
    };

    // Refresh token
    const refreshToken = async () => {
        try {
            const response = await fetch("/api/refresh-token", {
                method: "POST",
                credentials: "include",
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem("authToken", data.newToken);
                const decodedToken = jwtDecode(data.newToken);
                setTokenExpiration(decodedToken.exp);
                console.log("Token refreshed, new expiration:", decodedToken.exp);
                setShowPopup(false); // Close the popup on successful refresh
                isPopupActive.current = false; // Reset popup state after refresh
            } else {
                console.error("Token refresh failed:", data.message);
                handleLogout();
            }
        } catch (error) {
            console.error("Error refreshing token:", error);
            handleLogout();
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        setIsLoggingOut(true); // Set logging out flag
        setLogin(false);
        setShowPopup(false);
        isPopupActive.current = false; // Reset popup state on logout
        clearTimeout(inactivityTimeoutRef.current); // Clear inactivity timeout
        clearExistingInterval(); // Clear token expiration interval
        removeUserActivityListeners(); // Remove user activity listeners after logout
        window.location.href = "/login"; // Ensures a hard redirect to the login page

    };

    // Clear previous interval if set
    const clearExistingInterval = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
    };

    // Set token expiration and start tracking inactivity when logged in
    useEffect(() => {
        const tokenData = getToken(); // Try to get the token on app load
        if (!tokenData) {
            console.log("No valid token on app load");
            setLoading(false); // Stop loading if there's no valid token
        } else {
            setLoading(false); // Stop loading when token is found and valid
        }
    }, []);

    // Handle token expiration and popup display
    useEffect(() => {
        if (tokenExpiration) {
            console.log("Starting token expiration check...");

            clearExistingInterval(); // Clear any previous interval before starting a new one

            const id = setInterval(() => {
                const tokenData = getToken(); // Retrieve the latest token
                if (tokenData) {
                    const { decodedToken, currentTime } = tokenData;
                    const timeLeft = decodedToken.exp - currentTime;
                    console.log("Time left before token expires:", timeLeft);

                    // Show popup when token is about to expire (within 15 seconds)
                    if (timeLeft <= 15 && timeLeft > 0) {
                        setShowPopup(true);
                        isPopupActive.current = true; // Mark popup as active
                    }

                    // Automatically log out when the token has expired
                    if (timeLeft <= 0) {
                        console.log("Token expired, logging out.");
                        handleLogout();
                    }
                } else {
                    console.log("No valid token, logging out.");
                    handleLogout(); // Logout if there's no valid token
                }
            }, 1000); // Check every second for more accuracy
            setIntervalId(id);

            return () => {
                clearExistingInterval(); // Clear interval when component unmounts or tokenExpiration changes
            };
        }
    }, [tokenExpiration]);

    // User activity handler
    const handleUserActivity = () => {
        const now = Date.now();
        if (loggedIn && !isPopupActive.current && !isLoggingOut) {
            setShowPopup(false); // Hide popup if showing
            clearTimeout(inactivityTimeoutRef.current);

            // Start inactivity timer
            inactivityTimeoutRef.current = setTimeout(() => {
                if (!isPopupActive.current) {
                    setShowPopup(true); // Show popup after inactivity
                    isPopupActive.current = true; // Mark popup as active
                }
            }, 285000); //if user is inactive 15 seconds before token expires show popup

            // Only refresh token if it's been more than 30 seconds since the last refresh
            if (now - lastTokenRefreshTime.current > 30000) {
                refreshToken();
                lastTokenRefreshTime.current = now;
            }
        }
    };

    // Add event listeners for user activity
    const addUserActivityListeners = () => {
        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("keypress", handleUserActivity);
    };

    // Remove event listeners for user activity
    const removeUserActivityListeners = () => {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keypress", handleUserActivity);
    };

    // Setup event listeners for user activity when logged in
    useEffect(() => {
        if (loggedIn) {
            addUserActivityListeners();
        } else {
            removeUserActivityListeners(); // Make sure to remove listeners when logged out
        }

        return () => {
            removeUserActivityListeners(); // Clean up listeners on unmount or login state change
        };
    }, [loggedIn]);

    // Start the countdown when the user logs in
    useEffect(() => {
        if (loggedIn) {
            const tokenData = getToken();
            if (tokenData) {
                const { decodedToken } = tokenData;
                setTokenExpiration(decodedToken.exp);
            }
        }
    }, [loggedIn]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen bg-gradient-to-r from-[#4f46e5] to-[#2195f1]">
                <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-[#4f46e5] border-t-[#2195f1]"></div>
                </div>
            </div>
        );
    }


    return (
        <Router>
            <div className={`App transition-colors duration-300 ease-in-out ${darkMode ? "dark" : ""}`}>
                <Navbar
                    loggedIn={loggedIn}
                    darkMode={darkMode}
                    toggleDarkMode={toggleDarkMode}
                    setLogin={setLogin}
                    handleLogout={handleLogout}
                />

                <Routes>
                    <Route path="/" element={loggedIn ? <Navigate to="/map" replace /> : <Home setImei={setImei} darkMode={darkMode} />} />
                    <Route path="/login" element={loggedIn ? <Navigate to="/map" replace /> : <Login setLogin={setLogin} darkMode={darkMode} />} />
                    <Route path="/signup" element={loggedIn ? <Navigate to="/map" replace /> : <Signup darkMode={darkMode} />} />
                    <Route path="/about" element={<About darkMode={darkMode} />} />
                    <Route path="/contact" element={<Contact darkMode={darkMode} />} />
                    <Route path="/faq" element={<FAQ darkMode={darkMode} />} />
                    <Route path="/freemap" element={<FreeMap imei={imei} darkMode={darkMode} />} />
                    {loggedIn && (
                        <>
                            <Route path="/map" element={<Map darkMode={darkMode} />} />
                            <Route path="/admin" element={<Admin darkMode={darkMode} />} />
                            <Route path="/account" element={<Account darkMode={darkMode} />} />
                        </>
                    )}
                    <Route path="*" element={<div>Page Not Found</div>} />
                </Routes>

                {showPopup && (
                    <Popup
                        setShowPopup={setShowPopup}
                        onContinue={refreshToken}
                        onLogout={handleLogout}
                    />
                )}
            </div>
        </Router>
    );
}

export default App;
