import { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import React from 'react';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import { Icon, Style, Stroke } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import axios from 'axios';
import IntervalSelector from './IntervalSelector'; // Import IntervalSelector

export default function MapComponent({ selectedDevice }) {
    const mapRef = useRef(null);
    const [deviceLocations, setDeviceLocations] = useState([]); // Current device location
    const [historyLocations, setHistoryLocations] = useState([]); // Device location history
    const [historyLayer, setHistoryLayer] = useState(null); // For history lines
    const [currentLocationLayer, setCurrentLocationLayer] = useState(null); // For current device marker
    const [animationMarker, setAnimationMarker] = useState(null);
    const [animationTimeout, setAnimationTimeout] = useState(null); // Track animation timeout
    const [showHistory, setShowHistory] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [error, setError] = useState(null);
    const [updateInterval, setUpdateInterval] = useState(1); // Default to 1 minute
    const [historyFilter, setHistoryFilter] = useState('today'); // Default is today's history
    const [isHistoryLoading, setIsHistoryLoading] = useState(false); // Track if history is loading
    const timerRef = useRef(null); // For location update timer

    const latestLocationRef = useRef(null); // To store the latest fetched device location

    const handleError = (error) => {
        if (error.code === 'ECONNABORTED') return 'Request timeout. Please try again.';
        if (error.response) {
            if (error.response.status === 404) return 'No data available for this device.';
            if (error.response.status === 400) return 'Bad request. Please check the device information.';
            return `Error ${error.response.status}: ${error.response.data.message || 'Something went wrong.'}`;
        } else if (error.request) return 'Network error. Please check your connection and try again.';
        return 'An unknown error occurred. Please try again.';
    };

    const fetchDeviceLocation = () => {
        if (selectedDevice) {
            axios.get(`/api/devices/locations?imei=${selectedDevice.imei}`, { withCredentials: true })
                .then(res => {
                    const location = res.data;
                    if (location.latitude && location.longitude) {
                        setDeviceLocations([location]);
                        latestLocationRef.current = location; // Save the latest location
                        const coordinate = fromLonLat([location.longitude, location.latitude]);
                        mapRef.current.getView().setCenter(coordinate);
                        mapRef.current.getView().setZoom(14);
                    } else {
                        setError('Unable to fetch device location.');
                    }
                })
                .catch(err => {
                    setError(handleError(err));
                });
        }
    };

    const fetchDeviceHistory = () => {
        if (selectedDevice) {
            setError(null);
            setIsHistoryLoading(true); // Disable buttons while loading
            axios.get(`/api/devices/${selectedDevice.imei}/history?filter=${historyFilter}`, { withCredentials: true })
                .then(response => {
                    const coordinates = response.data.map(loc => fromLonLat([loc.longitude, loc.latitude]));
                    setHistoryLocations(coordinates);
                    addHistoryLayer(coordinates);
                })
                .catch(err => setError(handleError(err)))
                .finally(() => setIsHistoryLoading(false)); // Re-enable buttons after loading
        }
    };

    const addCurrentLocationLayer = (coordinate) => {
        if (currentLocationLayer) {
            mapRef.current.removeLayer(currentLocationLayer);
        }

        const vectorSource = new VectorSource();
        const markerFeature = new Feature({ geometry: new Point(coordinate) });

        const markerStyle = new Style({
            image: new Icon({
                src: 'https://cdn.icon-icons.com/icons2/1808/PNG/512/map-pin_115251.png',
                scale: 0.05,
            }),
        });
        markerFeature.setStyle(markerStyle);
        vectorSource.addFeature(markerFeature);

        const markerLayer = new VectorLayer({ source: vectorSource });
        mapRef.current.addLayer(markerLayer);
        setCurrentLocationLayer(markerLayer);

        setAnimationMarker(markerFeature); // Set animation marker
    };

    const addHistoryLayer = (coordinates) => {
        if (historyLayer) {
            mapRef.current.removeLayer(historyLayer);
        }

        const vectorSource = new VectorSource();
        const lineFeature = new Feature({ geometry: new LineString(coordinates) });

        const lineStyle = new Style({
            stroke: new Stroke({
                color: 'blue',
                width: 2,
            }),
        });
        lineFeature.setStyle(lineStyle);
        vectorSource.addFeature(lineFeature);

        const vectorLayer = new VectorLayer({ source: vectorSource });
        mapRef.current.addLayer(vectorLayer);
        setHistoryLayer(vectorLayer);
    };

    const startAnimation = (coordinates) => {
        if (!animationMarker) return;

        setIsAnimating(true);
        let index = 0;
        const moveMarker = () => {
            if (index < coordinates.length) {
                animationMarker.getGeometry().setCoordinates(coordinates[index]);
                index++;
                setAnimationTimeout(setTimeout(moveMarker, 50));
            } else {
                setIsAnimating(false);
            }
        };
        moveMarker();
    };

    const clearAnimation = () => {
        if (animationTimeout) {
            clearTimeout(animationTimeout);
            setAnimationTimeout(null);
        }
        setIsAnimating(false);
    };

    const removeHistoryLayer = () => {
        if (historyLayer) {
            mapRef.current.removeLayer(historyLayer);
            setHistoryLayer(null);
        }
    };

    const restoreLatestLocationMarker = () => {
        if (latestLocationRef.current) {
            const coordinate = fromLonLat([latestLocationRef.current.longitude, latestLocationRef.current.latitude]);
            addCurrentLocationLayer(coordinate);
        }
    };

    useEffect(() => {
        if (selectedDevice) {
            fetchDeviceLocation();
        }
    }, [selectedDevice]);

    useEffect(() => {
        if (deviceLocations.length > 0) {
            const [location] = deviceLocations;
            const coordinate = fromLonLat([location.longitude, location.latitude]);
            addCurrentLocationLayer(coordinate);
        }
    }, [deviceLocations]);

    useEffect(() => {
        if (selectedDevice && showHistory) {
            fetchDeviceHistory();
        } else {
            clearAnimation();
            removeHistoryLayer();
            restoreLatestLocationMarker();
        }
    }, [showHistory, selectedDevice, historyFilter]);

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = new Map({
                target: 'map',
                layers: [new TileLayer({ source: new OSM() })],
                view: new View({ center: fromLonLat([0, 0]), zoom: 2 }),
            });
        }
    }, []);

    useEffect(() => {
        if (historyLocations.length > 0 && isAnimating) startAnimation(historyLocations);
    }, [isAnimating]);

    useEffect(() => {
        if (timerRef.current) clearInterval(timerRef.current);
        if (selectedDevice && updateInterval) {
            timerRef.current = setInterval(fetchDeviceLocation, updateInterval * 60000);
        }
        return () => {
            if (timerRef.current) clearInterval(timerRef.current);
        };
    }, [selectedDevice, updateInterval]);

    const handleShowHistory = () => {
        if (isAnimating) clearAnimation();
        setShowHistory(prev => !prev);
    };

    const handleHistoryFilterChange = (e) => {
        setHistoryFilter(e.target.value); // Change filter between 'today' and '30days'
        setIsHistoryLoading(true); // Disable the dropdown temporarily
        fetchDeviceHistory(); // Fetch new history
    };

    const handlePlayAnimation = () => {
        if (historyLocations.length > 0 && !isAnimating) setIsAnimating(true);
    };

    return (
        <div className="p-4 bg-white dark:bg-gray-800 rounded-lg">
            <div id="map" className="w-full rounded-lg mb-4 h-[40rem]"></div>
            {error && <p className="text-red-500">{error}</p>}

            <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
                {selectedDevice && (
                    <IntervalSelector onIntervalChange={setUpdateInterval} />
                )}

                <div className="flex flex-wrap justify-center items-center gap-2 md:gap-2 lg:gap-4">
                    <button
                        onClick={handleShowHistory}
                        className="px-3 py-2 h-10 bg-blue-500 text-white rounded-lg hover:bg-blue-600 w-full md:w-auto transition duration-300 ease-in-out text-sm"
                        disabled={isHistoryLoading}
                    >
                        {showHistory ? "Hide History" : "Show History"}
                    </button>
                    {showHistory && (
                        <>
                            <select
                                value={historyFilter}
                                onChange={handleHistoryFilterChange}
                                className="px-3 py-2 h-10 bg-gray-500 text-white rounded-lg text-sm w-full md:w-auto"
                                disabled={isHistoryLoading}
                            >
                                <option value="today">Today's History</option>
                                {/* <option value="30days">Last 30 Days</option> */}
                            </select>
                            <button
                                onClick={handlePlayAnimation}
                                disabled={isAnimating || isHistoryLoading}
                                className={`px-3 py-2 h-10 bg-green-500 text-white rounded-lg ${
                                    isAnimating ? "opacity-50 cursor-not-allowed" : "hover:bg-green-600"
                                } w-full md:w-auto transition duration-300 ease-in-out text-sm`}
                            >
                                Play History
                            </button>
                        </>
                    )}
                    <button
                        id="zoom-out"
                        onClick={() => mapRef.current.getView().setZoom(mapRef.current.getView().getZoom() - 1)}
                        className="px-3 py-2 h-10 bg-gray-500 text-white rounded-lg hover:bg-gray-600 w-full md:w-auto transition duration-300 ease-in-out text-sm"
                    >
                        Zoom out
                    </button>
                    <button
                        id="zoom-in"
                        onClick={() => mapRef.current.getView().setZoom(mapRef.current.getView().getZoom() + 1)}
                        className="px-3 py-2 h-10 bg-gray-500 text-white rounded-lg hover:bg-gray-600 w-full md:w-auto transition duration-300 ease-in-out text-sm"
                    >
                        Zoom in
                    </button>
                </div>
            </div>
        </div>
    );
}
