// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nav {

    position: fixed;

    top: 0px;

    left: 0px;

    right: 0px;

    display: flex;

    height: 4rem;

    align-items: center;

    justify-content: space-between;

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity));

    padding-left: 1rem;

    padding-right: 1rem
}

.Nav:is(.dark *) {

    --tw-bg-opacity: 1;

    background-color: rgb(31 41 55 / var(--tw-bg-opacity))
}

  .pages {

    display: flex;

    align-items: center
}

  .pages > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(1rem * var(--tw-space-x-reverse));

    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

  .pages span {

    --tw-text-opacity: 1;

    color: rgb(55 65 81 / var(--tw-text-opacity))
}

  .pages span:hover {

    text-decoration-line: underline
}

  .pages span:is(.dark *) {

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}

  .profile-icon {

    position: relative
}

  .profile-icon .dropdown {

    position: absolute;

    right: 0px;

    top: 2rem;

    border-radius: 0.375rem;

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity));

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

  .dropdown .p-2 {

    display: flex;

    align-items: center
}

  .dropdown .p-2 span {

    --tw-text-opacity: 1;

    color: rgb(55 65 81 / var(--tw-text-opacity))
}

  .dropdown .p-2 span:is(.dark *) {

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar.css"],"names":[],"mappings":"AACI;;IAAA,eAA0G;;IAA1G,QAA0G;;IAA1G,SAA0G;;IAA1G,UAA0G;;IAA1G,aAA0G;;IAA1G,YAA0G;;IAA1G,mBAA0G;;IAA1G,8BAA0G;;IAA1G,kBAA0G;;IAA1G,yDAA0G;;IAA1G,kBAA0G;;IAA1G;AAA0G;;AAA1G;;IAAA,kBAA0G;;IAA1G;AAA0G;;EAI1G;;IAAA,aAAkC;;IAAlC;AAAkC;;EAAlC;;IAAA,uBAAkC;;IAAlC,oDAAkC;;IAAlC;AAAkC;;EAIlC;;IAAA,oBAAuD;;IAAvD;AAAuD;;EAAvD;;IAAA;AAAuD;;EAAvD;;IAAA,oBAAuD;;IAAvD;AAAuD;;EAIvD;;IAAA;AAAe;;EAIf;;IAAA,kBAA8D;;IAA9D,UAA8D;;IAA9D,SAA8D;;IAA9D,uBAA8D;;IAA9D,kBAA8D;;IAA9D,yDAA8D;;IAA9D,6EAA8D;;IAA9D,iGAA8D;;IAA9D;AAA8D;;EAI9D;;IAAA,aAAwB;;IAAxB;AAAwB;;EAIxB;;IAAA,oBAAuC;;IAAvC;AAAuC;;EAAvC;;IAAA,oBAAuC;;IAAvC;AAAuC","sourcesContent":[".Nav {\n    @apply fixed top-0 left-0 right-0 h-16 bg-gray-200 dark:bg-gray-800 flex items-center justify-between px-4;\n  }\n\n  .pages {\n    @apply flex items-center space-x-4;\n  }\n\n  .pages span {\n    @apply text-gray-700 dark:text-gray-300 hover:underline;\n  }\n\n  .profile-icon {\n    @apply relative;\n  }\n\n  .profile-icon .dropdown {\n    @apply absolute right-0 top-8 bg-gray-200 shadow-md rounded-md;\n  }\n\n  .dropdown .p-2 {\n    @apply flex items-center;\n  }\n\n  .dropdown .p-2 span {\n    @apply text-gray-700 dark:text-gray-300;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
