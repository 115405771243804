import React, { useEffect, useState, useRef } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import axios from 'axios';

export default function FreeMap({ imei, darkMode }) {
    const [deviceLocation, setDeviceLocation] = useState(null);
    const [errorMessage, setErrorMessage] = useState(''); // To handle error messages
    const mapRef = useRef(null);

    useEffect(() => {
        const fetchDeviceDetails = async () => {
            try {
                // Fetch device details, claim status, and location
                const response = await axios.get(`/api/device-details?imei=${imei}`, {
                    validateStatus: function (status) {
                        // Let any status code be resolved here instead of throwing an error
                        return true; // Resolve promise for all status codes
                    }
                });
    
                const data = response.data;
    
                // Handle 403 (device claimed)
                if (response.status === 403) {
                    setErrorMessage(data.error || 'This device has already been claimed by another user.');
                    return;
                }
    
                // Handle 404 (device not found)
                if (response.status === 404) {
                    setErrorMessage(data.error || 'The device does not exist.');
                    return;
                }
    
                // Handle valid location data
                if (data.latitude && data.longitude) {
                    setDeviceLocation({
                        latitude: data.latitude,
                        longitude: data.longitude,
                        timestamp: data.timestamp
                    });
                } else {
                    setErrorMessage('No location data available for this device.');
                }
            } catch (error) {
                // Handle unexpected errors (e.g. network errors)
                setErrorMessage('An error occurred while fetching device information.');
            }
        };
    
        fetchDeviceDetails();
    }, [imei]);
    

    useEffect(() => {
        if (deviceLocation && !mapRef.current) {
            mapRef.current = new Map({
                target: 'map',
                layers: [
                    new TileLayer({
                        source: new OSM(),
                    })
                ],
                view: new View({
                    center: fromLonLat([deviceLocation.longitude, deviceLocation.latitude]),
                    zoom: 10,
                })
            });

            const vectorSource = new VectorSource();
            const deviceFeature = new Feature({
                geometry: new Point(fromLonLat([deviceLocation.longitude, deviceLocation.latitude])),
            });

            deviceFeature.setStyle(new Style({
                image: new Icon({
                    src: 'https://cdn.icon-icons.com/icons2/1808/PNG/512/map-pin_115251.png',
                    scale: 0.05,
                })
            }));

            vectorSource.addFeature(deviceFeature);
            const vectorLayer = new VectorLayer({
                source: vectorSource,
            });

            mapRef.current.addLayer(vectorLayer);
        }
    }, [deviceLocation]);

    return (
        <div
            className={`min-h-screen flex flex-col items-center justify-center transition-colors duration-300 ease-in-out ${
                darkMode ? 'dark-image' : 'light-image'
            }`}
            style={{ backgroundImage: `url('${darkMode ? '/map-dark.png' : '/map-light.png'}')` }}
        >
            <div className="bg-white dark:bg-gray-800 bg-opacity-70 p-6 rounded-lg shadow-md w-full max-w-7xl h-126 mt-6">
                <h3 className="text-center text-gray-900 dark:text-gray-100 font-semibold mb-4">
                    Device IMEI: {imei}
                </h3>

                <div id="map" className="map-container w-full h-96 rounded-lg shadow-lg"></div>

                {errorMessage && (
                    <p className="text-center text-red-500 mt-4">
                        {errorMessage}
                    </p>
                )}

                {!deviceLocation && !errorMessage && (
                    <p className="text-center text-gray-700 dark:text-gray-300 mt-4">
                        Loading device location...
                    </p>
                )}

                {deviceLocation && (
                    <p className="text-center text-gray-700 dark:text-gray-300 mt-4">
                        Last updated: {deviceLocation.timestamp}
                    </p>
                )}
            </div>

            {/* Footer Section */}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out flex justify-center items-center shadow-lg p-4 text-center">
                <p className="text-gray-500 dark:text-gray-400">
                    &copy; 2024 Liveblip. All rights reserved.
                </p>
            </div>
        </div>
    );
}
