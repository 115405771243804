// Popup.js
import React from "react";
import { useNavigate } from "react-router-dom";

const Popup = ({ setShowPopup, onContinue, onLogout, darkMode }) => {
    const navigate = useNavigate();

    // Handle the logout action and navigate to login
    const handleLogout = () => {
        onLogout(); // Call the logout function passed as a prop
        navigate("/login"); // Navigate to the login page after logging out
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className={`bg-white dark:bg-gray-800 p-6 rounded shadow-lg max-w-md w-full`}>
                <h2 className="text-lg font-bold dark:text-white">Session Expired</h2>
                <p className="text-gray-700 dark:text-gray-300">
                    Your session is about to expire. Do you want to continue working?
                </p>
                <div className="mt-4 flex justify-end">
                    <button
                        className="mr-2 bg-blue-500 text-white p-2 rounded transition duration-300 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-500"
                        onClick={() => {
                            onContinue(); // Call the refresh function passed as a prop
                            setShowPopup(false);
                        }}
                    >
                        Continue
                    </button>
                    <button
                        className="bg-red-500 text-white p-2 rounded transition duration-300 hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-500"
                        onClick={handleLogout} // Use handleLogout for navigation
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
