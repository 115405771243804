import React, { useState, useEffect } from "react";

export default function FAQ({ darkMode }) {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            className={`min-h-screen flex flex-col items-center justify-center bg-fixed bg-cover bg-center transition-colors duration-300 ease-in-out ${
                darkMode ? "dark-image" : "light-image"
            } px-4 py-8`}
            style={{ backgroundImage: `url('${darkMode ? "/map-dark.png" : "/map-light.png"}')` }}
        >
            <div className="bg-white dark:bg-gray-800 bg-opacity-70 p-6 md:p-8 rounded-lg shadow-md w-full max-w-2xl overflow-auto">
                {!isMobile && (
                    <img
                        alt="decoration"
                        src={darkMode ? "/logo-light.png" : "/logo-dark.png"}
                        className="transition-colors duration-500 ease-in-out mx-auto mb-4"
                    />
                )}
                <h1 className="text-xl md:text-2xl font-bold text-gray-900 dark:text-gray-100 text-center mb-6">
                    Frequently Asked Questions (FAQ)
                </h1>

                <div className="text-gray-700 dark:text-gray-300 text-md md:text-lg mt-4">
                    <h2 className="font-semibold">1. What is Liveblip?</h2>
                    <p className="mb-4">
                        Liveblip is a GPS tracking application that allows users to monitor the
                        location of their devices in real time.
                    </p>

                    <h2 className="font-semibold mt-4">2. How does the GPS tracking work?</h2>
                    <p className="mb-4">
                        Our application uses advanced GPS technology to provide accurate and real-time
                        location data for devices registered within the system.
                    </p>

                    <h2 className="font-semibold mt-4">3. How can I add a device?</h2>
                    <p className="mb-4">
                        On the back of your device there will be an IMEI number this number will then
                        be typed into the home page to use the free version or if you are a registered
                        user you can login and click add a device in the devices section and
                        follow the prompts to input your device's details.

                    </p>

                    <h2 className="font-semibold mt-4">4. What should I do if I experience connectivity issues?</h2>
                    <p className="mb-4">
                        If you experience connectivity issues, please ensure that your device is within
                        a good network range and that location services are enabled. You can also restart
                        the app or your device.
                    </p>

                    <h2 className="font-semibold mt-4">5. How can I contact support?</h2>
                    <p className="mb-4">
                        For any inquiries or support, please contact us at
                        <a href="mailto:support@liveblip.com" className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"> support@liveblip.com</a>.
                    </p>
                </div>
            </div>
            {/* Footer Section */}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out flex justify-center items-center shadow-lg p-4 text-center">
                <p className="text-sm md:text-base text-gray-500 dark:text-gray-400">
                    &copy; 2024 Liveblip. All rights reserved.
                </p>
            </div>
        </div>
    );
}
