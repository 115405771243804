import React from "react";

export default function ContactUs({ darkMode }) {
    return (
        <div
            className={`min-h-screen flex items-center justify-center bg-fixed bg-cover bg-center transition-colors duration-300 ease-in-out ${
                darkMode ? "dark-image" : "light-image"
            }`}
            style={{ backgroundImage: `url('${darkMode ? "/map-dark.png" : "/map-light.png"}')` }}
        >
            <div className="bg-white dark:bg-gray-800 bg-opacity-70 p-8 rounded-lg shadow-md w-full max-w-2xl">
                <img
                    alt="decoration"
                    src={darkMode ? "/logo-light.png" : "/logo-dark.png"}
                    className="transition-colors duration-500 ease-in-out mx-auto mb-4"
                />
                <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 text-center mb-6">
                    Contact Us
                </h1>
                <p className="text-gray-700 dark:text-gray-300 text-lg">
                    We would love to hear from you! For any inquiries or support, please reach out
                    to us using the following contact information:
                </p>
                <ul className="text-gray-700 dark:text-gray-300 text-lg mt-4 list-disc list-inside">
                    <li>Email: <a href="mailto:support@liveblip.com" className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"> support@liveblip.com</a>.</li>
                    <li>Phone: +27 456 7890</li>
                    <li>Address: 91 Vlagskip St, Laser Park, Roodepoort, 2040</li>
                </ul>
            </div>
            {/* Footer Section */}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out flex justify-center items-center shadow-lg p-4 text-center">
                <p className="text-gray-500 dark:text-gray-400">
                    &copy; 2024 Liveblip. All rights reserved.
                </p>
            </div>
        </div>
    );
}
