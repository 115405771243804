import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "./popup"; // Import the Modal component

export default function Devices({ setSelectedDevice, selectedDevice }) {
    const [devices, setDevices] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [newDevice, setNewDevice] = useState({
        imei: "",
        model: "",
        name: "",
    });
    const [editingDevice, setEditingDevice] = useState(null);
    const [newName, setNewName] = useState('');
    const [error, setError] = useState(null);

    // Validate IMEI input (basic: length 15)
    const validateImei = (imei) => {
        if (!imei) {
            setError("IMEI is required.");
            return false;
        } else if (imei.length !== 15) {
            setError("Invalid IMEI number. Must be exactly 15 digits.");
            return false;
        } else if (!/^\d+$/.test(imei)) {
            setError("IMEI must contain only numbers.");
            return false;
        }
        setError(""); // No errors
        return true;
    };

    // Enhanced handleError function to capture backend messages
    const handleError = (error) => {
        if (error.response) {
            const backendMessage = error.response.data.error || error.response.data.message;
            return backendMessage
                ? backendMessage
                : `Error ${error.response.status}: Something went wrong.`;
        } else if (error.request) {
            return "Network error. Please check your connection and try again.";
        } else {
            return "An unknown error occurred. Please try again.";
        }
    };

    useEffect(() => {
        fetchDevices();
    }, []);

    const fetchDevices = () => {
        setError(null);
        axios
            .get("/api/devices", { withCredentials: true })
            .then((res) => {
                setDevices(res.data);
            })
            .catch((err) => {
                const errorMessage = handleError(err);
                setError(errorMessage);
            });
    };

    const handleCreateDevice = (e) => {
        e.preventDefault();
        setError(null);

        // Validate IMEI before creating device
        if (!validateImei(newDevice.imei)) {
            return; // Stop if validation fails
        }

        axios
            .post("/api/devices", newDevice, { withCredentials: true })
            .then(() => {
                fetchDevices();
                setShowForm(false);
                setNewDevice({ imei: "", model: "", name: "" });
            })
            .catch((err) => {
                const errorMessage = handleError(err);
                setError(errorMessage);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewDevice({
            ...newDevice,
            [name]: value,
        });
        // Validate IMEI in real-time as the user types
        if (name === "imei") {
            validateImei(value);
        }
    };

    const handleDeviceClick = (device) => {
        setError(null);
        axios.get(`/api/devices/locations?imei=${device.imei}`, { withCredentials: true })
            .then((res) => {
                const location = res.data;
                if (location && location.latitude && location.longitude) {
                    setSelectedDevice({
                        imei: device.imei,
                        latitude: location.latitude,
                        longitude: location.longitude,
                        name: device.name,
                    });
                } else {
                    setError('Location not found for this device.');
                }
            })
            .catch((err) => {
                const errorMessage = handleError(err);
                setError(errorMessage);
            });
    };

    const handleEditClick = (device) => {
        setEditingDevice(device.imei);
        setNewName(device.name);
    };

    const handleUpdateDeviceName = (device) => {
        setError(null);
        axios
            .put(`/api/devices/${device.imei}`, { name: newName }, { withCredentials: true })
            .then(() => {
                fetchDevices();
                setEditingDevice(null);
                if (selectedDevice && selectedDevice.imei === device.imei) {
                    setSelectedDevice({
                        ...selectedDevice,
                        name: newName,
                    });
                }
            })
            .catch((err) => {
                const errorMessage = handleError(err);
                setError(errorMessage);
            });
    };

    const handleCancelEdit = () => {
        setEditingDevice(null);
        setNewName('');
    };

    const handleRemoveDevice = (device) => {
        setError(null);
        axios
            .delete(`/api/devices/${device.imei}`, { withCredentials: true })
            .then(() => {
                fetchDevices();
                alert("Device removed successfully");
                if (selectedDevice && selectedDevice.imei === device.imei) {
                    setSelectedDevice(null);
                }
            })
            .catch((err) => {
                const errorMessage = handleError(err);
                setError(errorMessage);
            });
    };

   return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl mx-auto">
        {devices.length === 0 ? (
            <p className="text-gray-600 dark:text-gray-300">Please add a device.</p>
        ) : (
            <ul className="list-none p-0 m-0 space-y-4">
                {devices.map((device) => (
                    <li
                        key={device.imei}
                        className="bg-gray-200 dark:bg-gray-700 p-4 rounded-lg shadow block"
                    >
                        {editingDevice === device.imei ? (
                            <div className="space-y-2">
                                <input
                                    type="text"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    className="w-full bg-white dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded-lg p-2"
                                />
                                <div className="flex space-x-2">
                                    <button
                                        onClick={() => handleUpdateDeviceName(device)}
                                        className="px-4 py-2 bg-green-500 text-white rounded-lg"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={handleCancelEdit}
                                        className="px-4 py-2 bg-gray-400 text-white rounded-lg"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <p className="text-gray-900 dark:text-gray-100">
                                    {device.name || device.model} (IMEI: {device.imei})
                                </p>
                                <div className="flex flex-col space-y-2">
                                    <button
                                        onClick={() => handleDeviceClick(device)}
                                        className="px-3 py-2 sm:px-4 sm:py-3 lg:px-6 lg:py-4 bg-blue-500 dark:bg-blue-500 text-white rounded-lg hover:bg-indigo-700 dark:hover:bg-indigo-600 transition duration-300 ease-in-out"
                                    >
                                        View on Map
                                    </button>
                                    <button
                                        onClick={() => handleEditClick(device)}
                                        className="px-3 py-2 sm:px-4 sm:py-3 lg:px-6 lg:py-4 bg-yellow-500 dark:bg-yellow-500 text-white rounded-lg hover:bg-yellow-700 dark:hover:bg-yellow-600 transition duration-300 ease-in-out"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleRemoveDevice(device)}
                                        className="px-3 py-2 sm:px-4 sm:py-3 lg:px-6 lg:py-4 bg-red-500 dark:bg-red-500 text-white rounded-lg hover:bg-red-700 dark:hover:bg-red-600 transition duration-300 ease-in-out"
                                    >
                                        Remove
                                    </button>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        )}
        <div className="flex justify-center items-center">
            <button
                onClick={() => setShowForm(true)}
                className="mt-4 px-3 py-2 sm:px-4 sm:py-3 lg:px-6 lg:py-4 bg-green-500 dark:bg-green-500 text-white rounded-lg hover:bg-green-700 dark:hover:bg-green-600 transition duration-300 ease-in-out"
            >
                Add New Device
            </button>
        </div>

            <Modal isOpen={showForm} onClose={() => setShowForm(false)}>
                <form
                    onSubmit={handleCreateDevice}
                    className="space-y-6 bg-white dark:bg-gray-800 p-6 rounded-lg"
                >
                    <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-gray-100 transition-colors duration-300 ease-in-out">
                        Add Device
                    </h2>
                    {/* IMEI Input with Floating Label */}
                    <div className="relative">
                        <input
                            id="imei"
                            type="text"
                            name="imei"
                            value={newDevice.imei}
                            onChange={handleChange}
                            className={`peer w-full p-2 pt-3 border ${
                                error && error.includes("IMEI")
                                    ? "border-red-500"
                                    : "border-gray-500"
                            } bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300 ease-in-out`}
                            placeholder=" "
                            required
                        />
                        <label
                            htmlFor="imei"
                            className="absolute left-3 top-1 text-gray-500 dark:text-gray-400 transition-all duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-3 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                            IMEI
                        </label>
                        {error && error.includes("IMEI") && (
                            <p className="text-red-500 text-sm mt-1">{error}</p>
                        )}
                    </div>

                    {/* Model Input with Floating Label */}
                    <div className="relative">
                        <input
                            id="model"
                            type="text"
                            name="model"
                            value={newDevice.model}
                            onChange={handleChange}
                            className="peer w-full p-2 pt-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 border dark:border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300 ease-in-out"
                            placeholder=" "
                            required
                        />
                        <label
                            htmlFor="model"
                            className="absolute left-3 top-1 text-gray-500 dark:text-gray-400 transition-all duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-3 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                            Model
                        </label>
                    </div>

                    {/* Name Input with Floating Label */}
                    <div className="relative">
                        <input
                            id="name"
                            type="text"
                            name="name"
                            value={newDevice.name}
                            onChange={handleChange}
                            className="peer w-full p-2 pt-3 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-200 border dark:border-gray-500  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition duration-300 ease-in-out"
                            placeholder=" "
                        />
                        <label
                            htmlFor="name"
                            className="absolute left-3 top-1 text-gray-500 dark:text-gray-400 transition-all duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-3 peer-focus:scale-75 peer-focus:-translate-y-6"
                        >
                            Name (Optional)
                        </label>
                    </div>

                    {/* Show general error message if exists */}
                    {error && !error.includes("IMEI") && (
                        <p className="text-red-500 text-sm mt-1">{error}</p>
                    )}

                    {/* Submit Button */}
                    <div className="flex space-x-4">
                        <button
                            type="submit"
                            className="flex-1 px-4 py-2 bg-blue-500 dark:bg-blue-500 text-white p-3 rounded-md hover:bg-indigo-700 dark:hover:bg-indigo-600 transition duration-300 ease-in-out"
                        >
                            Add Device
                        </button>
                        <button
                            type="button"
                            onClick={() => setShowForm(false)} // Cancel button to close the modal
                            className="flex-1 px-4 py-2 bg-red-500 dark:bg-red-500 text-white p-3 rounded-md hover:bg-red-700 dark:hover:bg-red-600 transition duration-300 ease-in-out"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}
