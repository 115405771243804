import React, { useState, useRef, useEffect } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid"; // Import icons from Heroicons
import Devices from "./MapComponents/Devices";
import View from "./MapComponents/View";
import Dashboard from "./MapComponents/Dashboard";

export default function Map({ darkMode }) {
    const [selectedDevice, setSelectedDevice] = useState(null); // State for selected device

    // View (Map) collapsibility state and ref
    const [isViewVisible, setIsViewVisible] = useState(true);
    const [viewHeight, setViewHeight] = useState("auto");
    const viewRef = useRef(null);

    // Devices collapsibility state and ref
    const [isDevicesVisible, setIsDevicesVisible] = useState(true);
    const [devicesHeight, setDevicesHeight] = useState("auto");
    const devicesRef = useRef(null);

    // Dashboard collapsibility state and ref
    const [isDashboardVisible, setIsDashboardVisible] = useState(true);
    const [dashboardHeight, setDashboardHeight] = useState("auto");
    const dashboardRef = useRef(null);

    // Toggle visibility for View (Map)
    const toggleView = () => setIsViewVisible((prev) => !prev);

    // Toggle visibility for Devices
    const toggleDevices = () => setIsDevicesVisible((prev) => !prev);

    // Toggle visibility for Dashboard
    const toggleDashboard = () => setIsDashboardVisible((prev) => !prev);

    // Update height dynamically for View (Map) on visibility change
    useEffect(() => {
        if (viewRef.current) {
            if (isViewVisible) {
                setViewHeight(`${viewRef.current.scrollHeight}px`);
                setTimeout(() => setViewHeight("auto"), 300);
            } else {
                setViewHeight(`${viewRef.current.scrollHeight}px`);
                setTimeout(() => setViewHeight("0px"), 10);
            }
        }
    }, [isViewVisible]);

    // Update height dynamically for Devices on visibility change
    useEffect(() => {
        if (devicesRef.current) {
            if (isDevicesVisible) {
                setDevicesHeight(`${devicesRef.current.scrollHeight}px`);
                setTimeout(() => setDevicesHeight("auto"), 300);
            } else {
                setDevicesHeight(`${devicesRef.current.scrollHeight}px`);
                setTimeout(() => setDevicesHeight("0px"), 10);
            }
        }
    }, [isDevicesVisible]);

    // Update height dynamically for Dashboard on visibility change
    useEffect(() => {
        if (dashboardRef.current) {
            if (isDashboardVisible) {
                setDashboardHeight(`${dashboardRef.current.scrollHeight}px`);
                setTimeout(() => setDashboardHeight("auto"), 300);
            } else {
                setDashboardHeight(`${dashboardRef.current.scrollHeight}px`);
                setTimeout(() => setDashboardHeight("0px"), 10);
            }
        }
    }, [isDashboardVisible]);

    return (
        <div
            className={`flex flex-col min-h-screen bg-fixed bg-cover bg-center transition-colors duration-300 ease-in-out ${
                darkMode ? "light-image" : "dark-image"
            }`}
            style={{
                backgroundImage: `url('${darkMode ? "map-dark.png" : "map-light.png"}')`,
            }}
        >
            {/* Main content area */}
            <div className="flex flex-grow flex-col md:flex-row p-4 space-y-4 md:space-y-0 md:space-x-4 pt-20">
                {/* Left column: Devices */}
                <div className="w-full md:w-1/5 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg overflow-auto">
                    {/* Devices header with collapsible icon button */}
                    <div className="relative">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-4 text-center">
                            My Devices
                        </h3>
                        <button onClick={toggleDevices} className="absolute right-0 top-0 md:hidden">
                            {isDevicesVisible ? (
                                <ChevronUpIcon className="h-6 w-6 text-blue-400" />
                            ) : (
                                <ChevronDownIcon className="h-6 w-6 text-blue-400" />
                            )}
                        </button>
                    </div>
                    {/* Smooth rolling collapse for Devices */}
                    <div
                        ref={devicesRef}
                        style={{ height: devicesHeight }}
                        className={`transition-all duration-300 overflow-hidden md:block`}
                    >
                        <Devices
                            setSelectedDevice={setSelectedDevice}
                            selectedDevice={selectedDevice}
                        />
                    </div>
                </div>

                {/* Center column: View (Map) */}
                <div className="w-full md:w-1/2 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
                    {/* Map header with collapsible icon button */}
                    <div className="relative">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-4 text-center">
                            Map
                        </h3>
                        <button onClick={toggleView} className="absolute right-0 top-0 md:hidden">
                            {isViewVisible ? (
                                <ChevronUpIcon className="h-6 w-6 text-blue-400" />
                            ) : (
                                <ChevronDownIcon className="h-6 w-6 text-blue-400" />
                            )}
                        </button>
                    </div>
                    {/* Smooth rolling collapse for View */}
                    <div
                        ref={viewRef}
                        style={{ height: viewHeight }}
                        className={`transition-all duration-300 overflow-hidden md:block`}
                    >
                        <View selectedDevice={selectedDevice} />
                    </div>
                </div>

                {/* Right column: Dashboard */}
                <div className="w-full md:w-1/3 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
                    {/* Dashboard header with collapsible icon button */}
                    <div className="relative">
                        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200 mb-4 text-center">
                            Device Dashboard
                        </h3>
                        <button onClick={toggleDashboard} className="absolute right-0 top-0 md:hidden">
                            {isDashboardVisible ? (
                                <ChevronUpIcon className="h-6 w-6 text-blue-400" />
                            ) : (
                                <ChevronDownIcon className="h-6 w-6 text-blue-400" />
                            )}
                        </button>
                    </div>
                    {/* Smooth rolling collapse for Dashboard */}
                    <div
                        ref={dashboardRef}
                        style={{ height: dashboardHeight }}
                        className={`transition-all duration-300 overflow-hidden md:block`}
                    >
                        <Dashboard selectedDevice={selectedDevice} />
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="bottom-0 left-0 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out flex justify-center items-center shadow-lg p-4 text-center">
                <p className="text-gray-500 dark:text-gray-400">
                    &copy; 2024 Liveblip. All rights reserved.
                </p>
            </div>
        </div>
    );
}
