import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid"; // Import from v2
import { useNavigate } from "react-router-dom";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
        .email("Invalid email")
        .matches(/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format")
        .required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/^[A-Za-z0-9!@#$%^&*()_+=-]*$/, "Invalid characters in password")
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
});

const Signup = ({ setActivePage, darkMode }) => {
    const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false); // State for confirm password visibility
    const [serverError, setServerError] = useState("");
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    // Initialize React Hook Form
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const handleSignup = async (data) => {
        setServerError("");
        setLoading(true); // Set loading to true when signup starts

        try {
            const res = await axios.post("http://030104.xyz/api/register", {
                first_name: data.firstName,
                last_name: data.lastName,
                password: data.password,
                email: data.email,
                phone: data.phone,
            });
            if (res.status === 200) {
                navigate("/login");
            }
        } catch (error) {
            setServerError("Signup failed. Please try again.");
        } finally {
            setLoading(false); // Set loading to false when signup finishes
        }
    };

    useEffect(() => {
        if (serverError) {
            const timer = setTimeout(() => {
                setServerError("");
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [serverError]);

    return (
        <div
            className={`min-h-screen flex items-center justify-center bg-fixed bg-cover bg-center transition-colors duration-300 ease-in-out ${
                darkMode ? "light-image" : "dark-image"
            }`}
            style={{
                backgroundImage: `url('${darkMode ? "map-dark.png" : "map-light.png"}')`,
                paddingTop: "15rem", // Adds padding to avoid overlapping with navbar
            }}
        >
            <div className="bg-white dark:bg-gray-800 bg-opacity-70 p-8 rounded-lg shadow-md w-full max-w-md mt-[-11%]">
                <h2 className="text-2xl font-bold text-center mb-6 text-gray-900 dark:text-gray-100 transition-colors duration-300 ease-in-out">
                    Sign Up
                </h2>
                {serverError && (
                    <div className="bg-red-100 dark:bg-red-800 text-red-700 dark:text-red-200 p-2 mb-4 rounded transition-colors duration-300 ease-in-out justify-center">
                        {serverError}
                    </div>
                )}
                <form onSubmit={handleSubmit(handleSignup)} className="space-y-6">
                    {/* First Name Input */}
                    <div className="relative">
                        <input
                            id="firstName"
                            type="text"
                            {...register("firstName")}
                            className={`peer w-full p-2 pt-3 border ${
                                errors.firstName
                                    ? "border-red-500"
                                    : "border-gray-300 dark:border-gray-500"
                            } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                                errors.firstName
                                    ? "focus:ring-red-500"
                                    : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            } transition-colors duration-300 ease-in-out`}
                            placeholder=" "
                        />
                        <label
                            htmlFor="firstName"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400 duration-300
                            transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                        >
                            First Name
                        </label>
                        {errors.firstName && (
                            <p className="text-red-500 dark:text-red-400 text-sm pl-2">
                                {errors.firstName.message}
                            </p>
                        )}
                    </div>

                    {/* Last Name Input */}
                    <div className="relative">
                        <input
                            id="lastName"
                            type="text"
                            {...register("lastName")}
                            className={`peer w-full p-2 pt-3 border ${
                                errors.lastName
                                    ? "border-red-500"
                                    : "border-gray-300 dark:border-gray-500"
                            } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                                errors.lastName
                                    ? "focus:ring-red-500"
                                    : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            } transition-colors duration-300 ease-in-out`}
                            placeholder=" "
                        />
                        <label
                            htmlFor="lastName"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                        >
                            Last Name
                        </label>
                        {errors.lastName && (
                            <p className="text-red-500 dark:text-red-400 text-sm pl-2">
                                {errors.lastName.message}
                            </p>
                        )}
                    </div>

                    {/* Email Input */}
                    <div className="relative">
                        <input
                            id="email"
                            type="email"
                            {...register("email")}
                            className={`peer w-full p-2 pt-3 border ${
                                errors.email
                                    ? "border-red-500"
                                    : "border-gray-300 dark:border-gray-500"
                            } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                                errors.email
                                    ? "focus:ring-red-500"
                                    : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            } transition-colors duration-300 ease-in-out`}
                            placeholder=" "
                        />
                        <label
                            htmlFor="email"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                        >
                            Email
                        </label>
                        {errors.email && (
                            <p className="text-red-500 dark:text-red-400 text-sm pl-2">
                                {errors.email.message}
                            </p>
                        )}
                    </div>

                    {/* Phone Input */}
                    <div className="relative">
                        <input
                            id="phone"
                            type="text"
                            {...register("phone")}
                            className={`peer w-full p-2 pt-3 border ${
                                errors.phone
                                    ? "border-red-500"
                                    : "border-gray-300 dark:border-gray-500"
                            } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                                errors.phone
                                    ? "focus:ring-red-500"
                                    : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            } transition-colors duration-300 ease-in-out`}
                            placeholder=" "
                        />
                        <label
                            htmlFor="phone"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                        >
                            Phone
                        </label>
                        {errors.phone && (
                            <p className="text-red-500 dark:text-red-400 text-sm pl-2">
                                {errors.phone.message}
                            </p>
                        )}
                    </div>

                    {/* Password Input */}
                    <div className="relative">
                        <input
                            id="password"
                            type={passwordVisible ? "text" : "password"} // Toggle between text and password
                            {...register("password")}
                            className={`peer w-full p-2 pt-3 border ${
                                errors.password
                                    ? "border-red-500"
                                    : "border-gray-300 dark:border-gray-500"
                            } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                                errors.password
                                    ? "focus:ring-red-500"
                                    : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            } transition-colors duration-300 ease-in-out`}
                            placeholder=" "
                        />
                        <label
                            htmlFor="password"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                        >
                            Password
                        </label>
                        <button
                            type="button"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            className="absolute right-3 top-3 text-gray-500 dark:text-gray-300 hover:text-indigo-500 dark:hover:text-indigo-300 focus:outline-none transition-colors"
                            aria-label={passwordVisible ? "Hide password" : "Show password"}
                        >
                            {passwordVisible ? (
                                <EyeIcon className="h-5 w-5" />
                            ) : (
                                <EyeSlashIcon className="h-5 w-5" />
                            )}
                        </button>
                        {errors.password && (
                            <p className="text-red-500 dark:text-red-400 text-sm pl-2">
                                {errors.password.message}
                            </p>
                        )}
                    </div>

                    {/* Confirm Password Input */}
                    <div className="relative">
                        <input
                            id="confirmPassword"
                            type={confirmPasswordVisible ? "text" : "password"} // Toggle between text and password
                            {...register("confirmPassword")}
                            className={`peer w-full p-2 pt-3 border ${
                                errors.confirmPassword
                                    ? "border-red-500"
                                    : "border-gray-300 dark:border-gray-500"
                            } bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 rounded-md focus:outline-none focus:ring-2 ${
                                errors.confirmPassword
                                    ? "focus:ring-red-500"
                                    : "focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            } transition-colors duration-300 ease-in-out`}
                            placeholder=" "
                        />
                        <label
                            htmlFor="confirmPassword"
                            className="absolute left-3 top-2 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                        >
                            Confirm Password
                        </label>
                        <button
                            type="button"
                            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                            className="absolute right-3 top-3 text-gray-500 dark:text-gray-300 hover:text-indigo-500 dark:hover:text-indigo-300 focus:outline-none transition-colors"
                            aria-label={confirmPasswordVisible ? "Hide password" : "Show password"}
                        >
                            {confirmPasswordVisible ? (
                                <EyeIcon className="h-5 w-5" />
                            ) : (
                                <EyeSlashIcon className="h-5 w-5" />
                            )}
                        </button>
                        {errors.confirmPassword && (
                            <p className="text-red-500 dark:text-red-400 text-sm pl-2">
                                {errors.confirmPassword.message}
                            </p>
                        )}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="w-full bg-blue-600 dark:bg-blue-500 text-white p-3 rounded-md hover:bg-indigo-700 dark:hover:bg-indigo-600 transition-colors duration-300 ease-in-out flex items-center justify-center "
                        disabled={loading}
                    >
                        {loading ? (
                            <svg
                                className="animate-spin h-5 w-5 mr-3 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 20a8 8 0 01-8-8H0c0 6.627 5.373 12 12 12v-4z"
                                ></path>
                            </svg>
                        ) : (
                            "Sign Up"
                        )}
                    </button>
                </form>
                <p className="text-center text-gray-500 dark:text-gray-400 mt-4">
                    Already have an account?{" "}
                    <button
                        onClick={() => navigate("/login")}
                        className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300"
                    >
                        Login
                    </button>
                </p>
            </div>

            {/* Footer Section */}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-gray-200 dark:bg-gray-800 transition-colors duration-500 ease-in-out flex justify-center items-center shadow-lg p-4 text-center">
                <p className="text-gray-500 dark:text-gray-400">
                    &copy; 2024 Liveblip. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Signup;
